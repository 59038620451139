import { isNull, parseAddress } from '../common';
import { instance } from './instance-api';

export interface PostUserAckOptions {
    referrer: string;
    address: string;
    signature: string;
    message: string;
}

export const postUserReferral = async (data: PostUserAckOptions) => {
    const response = await instance
        .post('/refer', {
            ...data,
            referrer: parseAddress(data.referrer),
            address: parseAddress(data.address),
        })
        .catch(() => null);
    return !isNull(response) && response.data === 'ok';
};
