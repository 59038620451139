import classNames from 'classnames';

import { Maybe, Size } from '@/utils/common';

type TextDetailProps = {
    caption: string | React.ReactNode;
    value: Maybe<string> | React.ReactNode;
    align?: AlignSetting;
    suffix?: Maybe<string> | React.ReactNode;
    size?: Size;
    orientation?: 'horizontal' | 'vertical';
    valueClassName?: string;
};

export const TextDetail = ({
    caption,
    value,
    suffix,
    align = 'left',
    size = 'medium',
    orientation = 'vertical',
    valueClassName,
}: TextDetailProps) => {
    return (
        <div
            className={classNames('font-medium flex justify-between', {
                'gap-1': size === 'small' && orientation === 'vertical',
                'gap-2': size === 'medium' && orientation === 'vertical',
                'gap-3': size === 'large' && orientation === 'vertical',
                'items-center': align === 'center',
                'items-end': align === 'right',
                'flex-col': orientation === 'vertical',
                'flex-row': orientation === 'horizontal',
            })}
        >
            <div
                className={classNames('c-secondary--text t__caption--c1 whitespace-nowrap', {
                    'mr-1': size === 'small' && orientation === 'horizontal',
                    'mr-2': size === 'medium' && orientation === 'horizontal',
                    'mr-3': size === 'large' && orientation === 'horizontal',
                })}
            >
                {caption}
            </div>
            <div
                className={classNames(
                    'text-white capitalize whitespace-nowrap',
                    {
                        't__caption--c1': size === 'small',
                        't__body--b2': size === 'medium',
                        't__heading--h5': size === 'large',
                    },
                    valueClassName,
                )}
            >
                {value}
            </div>
            <div
                className={classNames('c-highlight--text', {
                    't__caption--c1': size === 'medium',
                })}
            >
                {suffix}
            </div>
        </div>
    );
};
