import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Cancel } from 'iconoir-react';
import { MouseEventHandler } from 'react';

export type ModalProps = {
    title?: string;
    body?: React.ReactNode;
    children?: React.ReactNode;
    isVisible: boolean;
    isClosable?: boolean;
    arrowSide?: 'right' | 'left' | 'right-edge';
    isArrowOutside?: boolean;
    modalWidth?: string;
    titleAlign?: 'left' | 'center';
    titlePadding?: string;
    bodyPadding?: string;
    onClose?: () => void;
};
export const Modal = ({
    title,
    body,
    children,
    isVisible = false,
    isClosable = true,
    arrowSide = 'right',
    titleAlign = 'center',
    isArrowOutside = true,
    titlePadding = 'px-6 pt-6',
    bodyPadding = 'px-6 pb-6',
    modalWidth = 'w-full md:w-[440px]',
    onClose,
}: ModalProps) => {
    const handleCloseClick: MouseEventHandler<HTMLButtonElement> = () => {
        onClose?.();
    };
    const handleOutsideClick: MouseEventHandler<HTMLDivElement> = (e) => {
        if (e.target === e.currentTarget && isClosable) onClose?.();
    };
    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -25 }}
                    className="fixed inset-0 flex flex-col justify-center px-4 md:px-0 bg-black/50 z-modal backdrop-filter backdrop-blur"
                    onClick={handleOutsideClick}
                >
                    <div
                        className={classNames(
                            modalWidth,
                            'md:mx-auto rounded-2xl bg-grey-60 shadow-card-lg relative ring-1 ring-grey-50',
                        )}
                    >
                        {title && (
                            <div
                                className={classNames(
                                    'modal__title t__heading--h6 text-white',
                                    {
                                        'text-center': titleAlign === 'center',
                                        'text-left': titleAlign === 'left',
                                    },
                                    titlePadding,
                                )}
                            >
                                {title}
                            </div>
                        )}
                        {isClosable && (
                            <button
                                className={classNames(
                                    'absolute top-6 z-10 text-neutral-80 hover:text-white',
                                    {
                                        'right-6': arrowSide === 'right',
                                        'right-0': arrowSide === 'right-edge',
                                        'left-6': arrowSide === 'left',
                                        '-top-12': isArrowOutside,
                                    },
                                )}
                                onClick={handleCloseClick}
                                aria-label="Close"
                            >
                                <Cancel fr="true" />
                            </button>
                        )}
                        <div className={classNames(bodyPadding)}>{body ? body : children}</div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
