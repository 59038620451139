import classNames from 'classnames';

import { Size } from '@/utils/common';

type PageHeadlineProps = {
    title: string;
    caption: string;
    size?: Size;
    align?: 'left' | 'right' | 'center';
};
export const PageHeadline = ({
    title,
    caption,
    size = 'large',
    align = 'center',
}: PageHeadlineProps) => {
    return (
        <div
            className={classNames('mx-auto py-12 space-y-2', {
                'text-center md:w-3/4': align === 'center',
            })}
        >
            <h1
                className={classNames('text-white', {
                    't__heading--h5': size === 'medium',
                    't__heading--h4': size === 'large',
                })}
            >
                {title}
            </h1>
            <div className="mx-auto t__body--b2 text-grey-20">{caption}</div>
        </div>
    );
};
