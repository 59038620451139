import { useAlerts } from '@/hooks/alerts';
import { useTranslation } from '@/hooks/translate';
import { useWallet } from '@/hooks/wallet';
import { appUserReferralAtom } from '@/state/app';
import { getUserReferral } from '@/utils/http/get-user-referral';
import { postUserReferral } from '@/utils/http/post-user-referral';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Button } from '../atom/Button';
import { Modal } from '../molecule/Modal';

export const ReferralModal: FC = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const { alertSuccess, alertError, alertInfo } = useAlerts();
    const { walletState, provider } = useWallet();
    const [referral, setReferral] = useRecoilState(appUserReferralAtom);
    const referralCode = router.query.r as string;

    useEffect(() => {
        try {
            if (referralCode && walletState.selectedAddress && !referral.loaded) {
                (async () => {
                    const hasReferral = await getUserReferral(walletState.selectedAddress!);
                    if (!hasReferral) {
                        setReferral({ ...referral, visible: true, loaded: true });
                    } else {
                        setReferral({ ...referral, loaded: true });
                    }
                })();
            }
        } catch {}
    }, [router.query, walletState.selectedAddress]);

    const postReferral = async () => {
        try {
            const message = t('modal.referral.signingMessage', { referrer: referralCode });
            let raw_message: any = message;
            alertInfo(t('modal.referral.verify'));
            const signature = await provider.send('personal_sign', [
                raw_message,
                walletState.selectedAddress,
            ]);
            const isValidReferral = await postUserReferral({
                address: walletState.selectedAddress!,
                message,
                signature,
                referrer: referralCode,
            });
            if (isValidReferral) {
                alertSuccess(t('modal.referral.success'));
                setReferral({ ...referral, visible: false });
            } else {
                alertError(t('error.referralExisting'));
            }
        } catch (e) {
            alertError(t('error.referral'));
        }
    };

    return (
        <Modal
            modalWidth="md:w-[22rem] w-full"
            isClosable={true}
            arrowSide="right-edge"
            isVisible={referral.visible}
            onClose={() => setReferral({ ...referral, visible: false })}
            body={
                <div className="flex flex-col items-center justify-center p-6">
                    <Image width={245} height={176} src="/images/bg-gift.svg" alt="Gift" />
                    <span className="mt-6 t__heading--h5 dark:text-white text-grey-100">
                        {t('modal.referral.title')}
                    </span>
                    <span className="mt-4 t__body--b3 dark:text-white text-grey-100">
                        {t('modal.referral.description')}
                    </span>
                    <Button
                        className="justify-center w-full mt-6 t__button--u1"
                        variant="primary"
                        onClick={() => postReferral()}
                        name={t('modal.referral.button')}
                    >
                        {t('modal.referral.button')}
                    </Button>
                </div>
            }
        />
    );
};
